import * as React from 'react';

import { Contact, Layout, Map, SEO, WhyPageComponent } from '../components';

export default function WhyPage() {
  return (
    <Layout>
      <SEO title="Why" />
      <WhyPageComponent />
      <Contact />
      <Map />
    </Layout>
  );
}
